import { registerApplication, start } from "single-spa";
import Keycloak from "keycloak-js";
import { checkPath } from "./helper/utils";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import pjson from "../package.json";
const eventData = [];

createSocketConnection();

Sentry.setTag("microfrontend", "root_conf");

Sentry.init({
  dsn:
    process.env.VUE_APP_SENTRY_URL ||
    "http://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10",
  release: `root_conf@${pjson.version}`,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
});

var token = "";
const options = {
  pkceMethod: process.env.KEYCLOAK_PKCE_METHOD || "S256",
  url: `https://${process.env.VUE_APP_SSO_DOMAIN}/auth/`,
  realm: process.env.MERCHANT_ID,
  clientId: process.env.KEYCLOAK_CLIENT_ID || "frontend",
  onLoad: process.env.KEYCLOAK_ON_LOAD || "login-required",
};

const _keycloak = Keycloak(options);
const noNavBar = checkPath(window.location.href);

_keycloak
  .init({ checkLoginIframe: false })
  .then(() => {
    if (!_keycloak.authenticated && !noNavBar) {
      _keycloak.login({
        redirectUri: window.location.href,
      });
    } else {
      token = _keycloak.idToken;
      setLocalStorage();
      /*global System*/
      /*eslint no-undef: "error"*/
      registerApplication({
        name: "@ottu-mf/navigation",
        app: () => System.import("@ottu-mf/navigation"),
        activeWhen: "/",
        customProps: {
          idToken,
          logout,
          translations,
          eventData,
          noNavBar: noNavBar || location.pathname.includes("/duplicate"),
          serverOrigin: process.env.VUE_APP_CORE_BACKEND_URL,
          firebaseAPIKey:
            process.env.VUE_APP_API_KEY ||
            "AIzaSyCRENY_ACvWS3Gk-fqR9ZvfTZJ2iuT6kE8",
          firebaseProjectId:
            process.env.VUE_APP_PROJECT_ID || "ottu-order-notification",
          firebaseSenderID: process.env.VUE_APP_SENDER_ID || "300985765733",
          firebaseAppId:
            process.env.VUE_APP_APP_ID ||
            "1:300985765733:web:08bfa30600d34036c49b8c",
          firebasePublicKey:
            process.env.VUE_APP_FIREBASE_PUBLIC_KEY ||
            "BIzFlm-RgFHW7vbEBxw9ojAZpKqGBUv-QPMDWvYZcKAo6IUWBBlVMO0eayt8PdcwOz_YcmOBKHM7gN-JRpcwHy0",
          sentryUrl:
            process.env.VUE_APP_SENTRY_URL ||
            "http://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10",
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
        },
      });

      registerApplication({
        name: "@ottu-mf/frontend",
        app: () => System.import("@ottu-mf/frontend"),
        activeWhen: [
          (location) => location.pathname.includes("/transactions"),
          (location) => location.pathname.includes("/bulk/"),
          (location) => location.pathname.includes("/catalogue"),
          (location) => location.pathname.includes("/customer-payment"),
          (location) => location.pathname.includes("/e-commerce"),
          (location) => location.pathname.includes("/event"),
          (location) => location.pathname.includes("/dashboard"),
          (location) => location.pathname.includes("/payment-request"),
          (location) => location.pathname.includes("/generated-reports"),
          (location) => location.pathname.includes("/food_ordering"),
          (location) => location.pathname.includes("/session"),
          (location) => location.pathname.includes("/shopify"),
          (location) => location.pathname.includes("/user"),
          (location) => location.pathname.includes("/help"),
          (location) => location.pathname.includes("/cpr"),
          (location) => location.pathname.includes("/checkout/"),
          (location) => location.pathname.includes("/events"),
          (location) => location.pathname.includes("/menu"),
          (location) => location.pathname.includes("/spf"),
          (location) => location.pathname.includes("/ticket"),
          (location) => location.pathname.includes("/pos"),
          (location) => location.pathname.includes("/real-estate"),
          (location) => location.pathname.includes("/search"),
        ],
        customProps: {
          idToken,
          logout,
          translations,
          noNavBar,
          sentryUrl:
            process.env.VUE_APP_SENTRY_URL ||
            "http://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10",
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          firebaseAPIKey:
            process.env.VUE_APP_API_KEY ||
            "AIzaSyCRENY_ACvWS3Gk-fqR9ZvfTZJ2iuT6kE8",
          firebaseProjectId:
            process.env.VUE_APP_PROJECT_ID || "ottu-order-notification",
          firebaseSenderID: process.env.VUE_APP_SENDER_ID || "300985765733",
          firebaseAppId:
            process.env.VUE_APP_APP_ID ||
            "1:300985765733:web:08bfa30600d34036c49b8c",
          firebasePublicKey:
            process.env.VUE_APP_FIREBASE_PUBLIC_KEY ||
            "BIzFlm-RgFHW7vbEBxw9ojAZpKqGBUv-QPMDWvYZcKAo6IUWBBlVMO0eayt8PdcwOz_YcmOBKHM7gN-JRpcwHy0",
          customStyleDirectoryName:
            process.env.VUE_APP_CUSTOM_STYLE_DIRECTORY_NAME,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          orderRefreshTime: process.env.VUE_APP_ORDER_LIST_REFRESH_TIME || 60,
          checkoutSDKLink:
            process.env.VUE_APP_CHECKOUT_SDK_URL ||
            "https://assets.ottu.net/checkout/v1/checkout.min.js",
          gatewayApiKey:
            process.env.VUE_APP_GATEWAY_API_KEY ||
            "L0Fc5f81.dLqByodGesaD9pJdzoKpo6rP1FQBkVzR",
          showFromforPaymentPage:
            process.env.VUE_APP_SHOW_FROM_IN_PAYMENT_DETAILS_PAGE === "false"
              ? false
              : true,
        },
      });
      registerApplication({
        name: "@ottu-mf/estate",
        app: () => System.import("@ottu-mf/estate"),
        activeWhen: [(location) => location.pathname.includes("/real_estate")],
        customProps: {
          idToken,
          logout,
          merchant: process.env.MERCHANT_ID,
          translations,
          gatewayApiKey:
            process.env.VUE_APP_GATEWAY_API_KEY ||
            "L0Fc5f81.dLqByodGesaD9pJdzoKpo6rP1FQBkVzR",
        },
      });

      registerApplication({
        name: "@ottu-mf/fnb_frontend",
        app: () => System.import("@ottu-mf/fnb_frontend"),
        activeWhen: [
          (location) => location.pathname.includes("/new-menu"),
          (location) => location.pathname.includes("/food-ordering"),
        ],
        customProps: {
          idToken,
          logout,
          merchant: process.env.VUE_APP_CORE_SERVER_MERCHANT,
          translations,
          sentryUrl:
            process.env.VUE_APP_SENTRY_URL ||
            "http://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10",
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          noNavBar: noNavBar || location.pathname.includes("/duplicate"),
          orderRefreshTime: process.env.VUE_APP_ORDER_LIST_REFRESH_TIME || 60,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
        },
      });
    }
  })
  .catch((err) => {
    console.log("Error: ", err);
  });

export function idToken() {
  return token ? token : "";
}

export function logout() {
  token = "";
  _keycloak.logout();
}

export function translations() {
  /*global require*/
  /*eslint no-undef: "error"*/
  const translations = require.context(
    "./translations",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  translations.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = translations(key);
    }
  });
  return messages;
}

function setLocalStorage() {
  localStorage.setItem(
    "VUE_APP_CORE_SERVER_ORIGIN",
    process.env.VUE_APP_CORE_BACKEND_URL
  );

  localStorage.setItem(
    "VUE_APP_GOOGLE_API_KEY",
    process.env.VUE_APP_GOOGLE_API_KEY ||
      "AIzaSyAzrErHOIrPyYNwwlIpbbDAB7L3jKsfkCA"
  );

  localStorage.setItem(
    "VUE_APP_POS_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_POS_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_POS_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_POS_PLUGIN_LANGUAGE_BUTTON || true
  );

  localStorage.setItem(
    "VUE_APP_EVENTS_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_EVENTS_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_EVENTS_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_EVENTS_PLUGIN_LANGUAGE_BUTTON || true
  );

  localStorage.setItem(
    "VUE_APP_CATALOGUE_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_CATALOGUE_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_CATALOGUE_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_CATALOGUE_PLUGIN_LANGUAGE_BUTTON || true
  );
}

function createSocketConnection() {
  const socket = new WebSocket(
    process.env.VUE_APP_WEB_SOCKET_URL ||
      "wss://vupr34v7v4.execute-api.eu-west-1.amazonaws.com/dev"
  );
  const payload_dict = {
    merchant_id: process.env.MERCHANT_ID,
    client: "frontend",
    project: "core_frontend",
    type: "report.Task",
  };

  socket.onopen = (event) => {
    console.log("Connection established", event);
    socket.send(JSON.stringify(payload_dict));
  };

  socket.onmessage = (event) => {
    eventData.pop();
    eventData.push(JSON.parse(event.data));
  };

  socket.onerror = (event) => {
    console.log("error occurs", event);
  };

  socket.onclose = () => {
    console.log("Connection is closed...");
  };
}
//Token Refresh
setInterval(() => {
  _keycloak
    .updateToken(-1)
    .then(() => {
      if (token) {
        token = _keycloak.idToken;
      }
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
}, 60000);

start();
